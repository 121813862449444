import { useState } from "react";
export default function Search(props) {
  const [clearBtn, setClearBtn] = useState(false);
  //search
  const [term, setTerm] = useState("");
  function getTerm() {
    props.searchTerm(term);
  }

  // function()
  return (
    <div className="inputsContainer">
      <div className="inputSearch">
        <input
          type="text"
          placeholder="Wpisz miasto..."
          onChange={(e) => {
            if (e.target.value.length >= 1) {
              setClearBtn(true);
            } else {
              setClearBtn(false);
            }
            setTerm(e.target.value);
          }}
          onKeyDown={(e) => e.key === "Enter" && getTerm()}
          value={term}
        />
        <button
          className="buttonSearch"
          onClick={() => {
            getTerm();
          }}
        ></button>
        <button
          style={{ display: `${clearBtn ? "" : "none"}` }}
          className="clearBtn"
          onClick={() => {
            setClearBtn(!clearBtn);
            setTerm("");
            props.resetTerm();
          }}
        >
          x
        </button>
      </div>
      <div className="checkmarks">
        <label className="containerCheck">
          <div className="imgWithText">
            <div className="pszokImg"></div>
            <p>PSZOK</p>
          </div>
          <input
            type="checkbox"
            name="PSZOK"
            defaultChecked={true}
            onClick={(e) => {
              props.pszokType(e.target.checked);
            }}
          />
          <span className="checkmark"></span>
        </label>
        <label className="containerCheck">
          <div className="imgWithText">
            <div className="pszokImg"></div>
            <p>ELektroodpady</p>
          </div>
          <input
            type="checkbox"
            name="Elektroodpady"
            defaultChecked={true}
            onClick={(e) => {
              props.elektroType(e.target.checked);
            }}
          />
          <span className="checkmark"></span>
        </label>
      </div>
      <button
        className={`return ${props.hide ? "hide" : ""}`}
        onClick={() => {
          setClearBtn(false);
          setTerm("");
          props.return(props.term);
        }}
      >
        Wróć do cała {props.currentPlace}
      </button>
    </div>
  );
}
