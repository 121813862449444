import { useEffect } from "react";

export default function List(props) {
    const PszokList = (element) => {
        return(
            <li 
                className='pszok'
                key={element.ID}
                >
                    <p className="bold">{element.nazwa}</p>
                    <p>{element.ulica},{element.miejscowosc},{element.wojewodztwo}</p>
                    <p>{element.wskazowki_dojazdu}</p>
                    <p>{element.operator}</p>
                    {element.telefon === undefined ? null : <p>telefon:{element.telefon}</p>}
                    {element.www === undefined ? null : <a className="margin bold underline" href={element.www} rel='noreferrer' target='_blank'>www</a>}
                    {element.wrzucamy === undefined ?  null : <div><p className="margin bold">wrzucamy:</p><p>{element.wrzucamy}</p></div> }
                    {element.nie_wrzucamy === undefined ?  null :  <div><p className="margin bold">nie wrzucamy:</p><p>{element.nie_wrzucamy}</p></div>}
                    <p className="margin bold">godziny otwarcia</p>
                    <p>{element.godziny_otwarcia_poniedzialek}</p>
                    <p>{element.wtorek}</p>
                    <p>{element.sroda}</p>
                    <p>{element.czwartek}</p>
                    <p>{element.piatek}</p>
                    <p>{element.sobota}</p>
                    <p>{element.niedziela}</p>
                    <p></p>
                    <button
                        className="margin bold underline"
                        onClick={async ()=>{
                           await props.hideList();
                           await props.CustomPosition(element.lat,element.lng)
                        // await props.CustomPosition(element.ID)
                        }}
                    >
                        Pokaż na mapie
                    </button>
                </li>
        )
    }

    function swapListFilter() {
       
        if(props.scopeData === "") {
            return(
                props.pszokData?.filter(list => 
                    list.miejscowosc?.toLowerCase().includes(props.cityFilter.toLowerCase())
                    &&
                    ((list.typ?.includes(props.TypesFilter[0])) || (list.typ?.includes(props.TypesFilter[1])))
                )
                .slice(0,props.size).map(pszok => {
                    return(
                        PszokList(pszok)
                    )
                })
            )
        }
        else {
           return( 
            props.scopeData?.map(el => {
                return(
                    PszokList(el)
                )
            })
           )
        }
    }

    useEffect(()=>{},[props.size])
    return(
        <div className="list">
            {
                swapListFilter()
            }
        </div>
    )
}