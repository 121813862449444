import { useRef, useState, useEffect } from "react";
import List from "./List/List";
import Map from "./Map/Map";
import Search from "./Search/Search";
import axios from "axios";

import double_arrow_bold from "../../images/double_arrow_bold.svg";

const SearchWithList = ({ children }) => (
  <div className="SearchWithList"> {children} </div>
);

export default function Pszok(props) {
  const [focusPszok, setFocusPszok] = useState("");
  const [focusElektro, setFocusElektro] = useState("");

  const list = document.querySelector(".SearchWithList");
  const btn = document.querySelector(".toggleBtn");

  const [currentPlace, setCurrentPlace] = useState("");
  const [pszokType, setpszokType] = useState("PSZOK");
  const [elektroType, setelektroType] = useState("Elektroodpady");
  const Types = [pszokType, elektroType];

  const [serachTerm, setSearchTerm] = useState("");

  const [pszokData, setpszokData] = useState([]);
  const [pszokDataMap, setpszokDataMap] = useState([]);

  const [scopeData, setScopeData] = useState("");

  const [isToggle, setIsToggle] = useState(false);
  const [showBtn, setShowBtn] = useState(true);

  const mapRef = useRef();

  const defaultProps = {
    center: {
      lat: 52.3957687668395,
      lng: 19.22097674919009,
    },
    zoom: 6.2,
  };
  //functions
  const pszok = () => {
    setpszokData(props.pszokData);
    setpszokDataMap(props.pszokData);
  };
  async function searchByCityName(term) {
    if (term === "") {
      defaultOptions();
      return;
    }
    if (pszokData.length === 1) {
      setShowBtn(true);
      pszok();
      return;
    }

    setSearchTerm(term);
    getCoords(term);
    ToggleListWithBtn();
    setShowBtn(false);
  }
  function resetMarkers() {
    setFocusElektro("");
    setFocusPszok("");
  }
  function setNewPosition(x, y) {
    setCurrentPlace(serachTerm ? serachTerm : "Polska");

    mapRef.current.panTo({ lat: parseFloat(x), lng: parseFloat(y) });
    mapRef.current.setZoom(18);
  }
  async function getCoords(city) {
    if (city === "") {
      return;
    }
    try {
      const geocodeURl = "https://maps.googleapis.com/maps/api/geocode/json?";
      const getData = await axios.get(geocodeURl, {
        params: {
          address: city,
          key: props.api_key,
        },
      });
      setCurrentPlace("Polska");
      // console.log(getData)
      //  await defaultOptions()
      const moveTo = await getData.data.results[0].geometry.location;
      await mapRef.current.panTo(moveTo);
      await mapRef.current.setZoom(12);
    } catch (error) {
      alert("Zła nazwa miejscowości");
      console.error(error);
    }
  }
  async function defaultOptions() {
    pszok();
    ToggleListWithBtn();
    setSearchTerm("");
    setScopeData("");
    resetMarkers();
    setShowBtn(true);
    await mapRef.current.panTo(defaultProps.center);
    await mapRef.current.setZoom(defaultProps.zoom);
  }
  async function defaultOptionsV2() {
    pszok();
    setSearchTerm("");
    setScopeData("");
    resetMarkers();
    setShowBtn(true);
    await mapRef.current.panTo(defaultProps.center);
    await mapRef.current.setZoom(defaultProps.zoom);
  }
  async function showInfo(id) {
    const findList = () => pszokData.find((el) => el.ID === id);

    if (list.classList.contains("hideList")) {
      ToggleListWithBtn();
    }
    mapRef.current.setZoom(18);
    setScopeData([findList()]);
    mapRef.current.panTo({
      lat: parseFloat(findList().lat),
      lng: parseFloat(findList().lng),
    });
  }

  function ToggleListWithBtn() {
    list.classList.toggle("hideList");
    btn.classList.toggle("hideBtn");
    setIsToggle(!isToggle);
  }

  const [size, setSize] = useState(80);
  let [scroll, setScroll] = useState();
  function lazyLoading(e) {
    e?.addEventListener("scroll", () => {
      setScroll(
        Math.round(
          (100 * e?.scrollTop) /
            e?.scrollHeight /
            (1 - e?.clientHeight / e?.scrollHeight)
        )
      );
    });
  }

  function addSize(size1, add) {
    setSize(size1 + add);
  }

  function foo() {
    if (scroll === 100 && size === 80) {
      addSize(size, 300);
    }
  }

  useEffect(() => {
    lazyLoading(list);
    if (scroll === 100) {
      foo();
    }
    pszok();
  });

  return (
    <div className="PszokApp">
      <button
        className="toggleBtn"
        onClick={() => {
          ToggleListWithBtn();
        }}
      >
        {isToggle ? (
          <div>
            <p>Pokaż</p>{" "}
            <img className="hidenBtn" src={double_arrow_bold} alt="arrow" />
          </div>
        ) : (
          <div>
            <p>Schowaj</p>{" "}
            <img className="openBtn" src={double_arrow_bold} alt="arrow" />
          </div>
        )}
      </button>
      <SearchWithList>
        <Search
          searchTerm={(term) => searchByCityName(term)}
          pszokType={(type) =>
            type ? setpszokType("PSZOK") : setpszokType("-PSZOK")
          }
          elektroType={(type) =>
            type
              ? setelektroType("Elektroodpady")
              : setelektroType("-Elektroodpady")
          }
          hide={showBtn}
          // defaultOptions={()=>defaultOptions()}
          currentPlace={currentPlace}
          term={serachTerm}
          resetTerm={() => defaultOptionsV2()}
          return={async (term) => {
            if ((mapRef.current.zoom <= 12 && term !== "") || term === "") {
              await defaultOptions();
            }

            if (mapRef.current.zoom > 12 && serachTerm === term) {
              setScopeData("");
              await getCoords(term);
              resetMarkers();
            }
          }}
        />
        <List
          hideList={() => {
            setShowBtn(false);
            ToggleListWithBtn();
          }}
          cityFilter={serachTerm}
          TypesFilter={Types}
          pszokData={pszokData}
          scopeData={scopeData}
          CustomPosition={(x, y) => setNewPosition(x, y)}
          size={size}
          // CustomPosition={(id)=>{showInfo(id)}}
        />
      </SearchWithList>

      <Map
        setFocusPszok={(pszokID) => {
          setFocusPszok(pszokID);
        }}
        setFocusElektro={(elektroID) => {
          setFocusElektro(elektroID);
        }}
        focusPszok={focusPszok}
        focusElektro={focusElektro}
        api_={props.api_key}
        pszokData={pszokDataMap}
        mapRef={mapRef}
        markerFilter={Types}
        returnerBtn={(btn) => setShowBtn(btn)}
        getId={(id) => showInfo(id)}
        returnName={(term) => {
          setCurrentPlace(term);
        }}
        serachTerm={serachTerm}
      />
    </div>
  );
}
